import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PageTemplate from '../components/page'

const Expertise = ({data}) => (
  <PageTemplate data={data} />
)

Expertise.propTypes = {
  data: PropTypes.object.isRequired
}

export default Expertise

export const pageQuery = graphql`
  query expertiseQuery {
    # Settings page content
    settings: allContentfulSettings (
      limit: 1
    ) {
      edges {
        node {
          ...Settings
        }
      }
    }
    # Projects
    projects: allContentfulProject (
      sort: {
        fields: [publishDate],
        order: DESC
      }
      filter: {
        externalLink: { ne: null }
      }
    ) {
      edges {
        node {
          ...ProjectInfo
        }
      }
    }
  }
`
